import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CustomFile } from '../entities/custom_file';
import { Inspection } from '../entities/inspections/inspection';
import { Inspector } from '../entities/inspector';
import { Purchase } from '../entities/purchase';
import { AuthService } from './auth/auth.service';
import { psi } from '../entities/forms/psi';

@Injectable({
  providedIn: 'root',
})
export class InspectionService {
  inspectorUrl = environment.apiUrl + '/inspector/inspection';
  clientUrl = environment.apiUrl + '/client/inspections';
  currentInspectionId!: string;
  modifyImageURL = environment.apiUrl + '/inspector/inspection/modifyMedia/';
  modifyPSIapiURL =
    environment.apiUrl + '/inspector/inspection/modifyInspection/';
  modifyTSapiURL =
    environment.apiUrl + '/inspector/inspection/modifyTSInspection/';
  addfaultyComponentapiURL =
    environment.apiUrl + '/inspector/inspection/addNewFaultyComponent/';
  purchaseapiURL = environment.apiUrl + '/inspector/purchases/purchase';
  modifyfaultyComponentapiURL =
    environment.apiUrl + '/inspector/inspection/modifyFaultyComponent/';
  deletefaultyComponentURL =
    environment.apiUrl + '/inspector/inspection/deleteFaultyComponent/';

  constructor(private auth: AuthService, private http: HttpClient) {}

  async editComponent(
    inspection_id: string,
    componentName: string,
    component: any
  ) {
    return firstValueFrom(
      this.http.patch<string>(
        `${this.inspectorUrl}/editComponent/${inspection_id}`,
        {
          component,
          componentName,
        }
      )
    );
  }

  async modifyInspection(
    inspection_id: string,
    updates: any,
    operation: boolean
  ) {
    const url = this.modifyPSIapiURL + inspection_id;
    let options = {
      updates,
      operation: operation,
    };

    return await firstValueFrom(this.http.patch<any>(url, options)).catch(
      (error) => {
        throw new Error(error);
      }
    );
  }

  async modifyMedia(inspectionId: string, options: any) {
    const url = this.modifyImageURL + inspectionId;
    if (options.operation == 'delete') {
      return firstValueFrom(this.http.delete<any>(url, options)).catch(
        (error) => {
          throw new Error(error);
        }
      );
    } else {
      return firstValueFrom(this.http.post<any>(url, options)).catch(
        (error) => {
          throw new Error(error);
        }
      );
    }
  }

  async createInspection(inspection: Inspection): Promise<string> {
    let response = firstValueFrom(
      this.http.post<string>(this.inspectorUrl + '/create', {
        data: inspection,
      })
    ).catch((error) => {
      throw new Error(error);
    });
    let inspectionPassword;
    [this.currentInspectionId, inspectionPassword] = await response;
    localStorage.setItem('currentInspection', this.currentInspectionId);

    return this.currentInspectionId;
  }

  async saveInspection(inspection: Inspection): Promise<string> {
    return firstValueFrom(
      this.http.patch<string>(this.inspectorUrl + `/${inspection._id}`, {
        data: inspection,
      })
    ).catch((error) => {
      throw new Error(error);
    });
  }

  async modifyTSInspection(
    inspectionId: string,
    updates: any,
    operation: boolean
  ) {
    const url = this.modifyTSapiURL + inspectionId + '/' + operation;

    return firstValueFrom(this.http.patch<any>(url, updates)).catch((error) => {
      throw new Error(error);
    });
  }

  async addFaultyComponent(inspectionId: string, updates: any) {
    const url = this.addfaultyComponentapiURL + inspectionId;

    return firstValueFrom(this.http.patch<any>(url, updates)).catch((error) => {
      throw new Error(error);
    });
  }
  async modifyFaultyComponent(
    inspectionId: string,
    componentKey: string,
    index: number,
    updatedData: { [key: string]: any }
  ): Promise<any> {
    try {
      // Prepare the payload for the request body
      const payload = {
        componentKey,
        index,
        updatedData,
      };

      // Construct the API endpoint URL
      const url = `${this.modifyfaultyComponentapiURL}${inspectionId}`;

      // Send the PATCH request
      return await firstValueFrom(this.http.patch<any>(url, payload));
    } catch (error) {
      console.error('Error modifying faulty component:', error);
    }
  }

  async deleteFaultyComponent(
    inspectionId: string,
    faultyComponentsDetails: any
  ): Promise<any> {
    const url = `${this.deletefaultyComponentURL}${inspectionId}`;

    try {
      return await firstValueFrom(
        this.http.delete<any>(url, { body: faultyComponentsDetails })
      );
    } catch (error) {
      throw new Error(error instanceof Error ? error.message : String(error));
    }
  }

  async getInspection(inspection_id: string) {
    return firstValueFrom(
      this.http.get<Inspection>(this.inspectorUrl + `/${inspection_id}`)
    ).catch((error) => {
      throw new Error(error);
    });
  }

  async getInspectionDetails(purchaseId: string) {
    return firstValueFrom(
      this.http.get(this.purchaseapiURL + '/' + purchaseId)
    ).catch((error) => {
      throw new Error(error);
    });
  }

  async getInspectorInfo(inspector_id: string) {
    return firstValueFrom(
      this.http.get<Inspector>(
        this.clientUrl + `/inspectorInfo/${inspector_id}`
      )
    ).catch((error) => {
      throw new Error(error);
    });
  }

  async getOrganizedInspection(
    inspection_id: string,
    inspection_password: string
  ) {
    const password_url = inspection_password ? '/' + inspection_password : '';
    return firstValueFrom(
      this.http.get<Inspection>(
        this.clientUrl + `/organized/${inspection_id}${password_url}`
      )
    ).catch((error) => {
      throw new Error(error);
    });
  }

  async getProducedInspections(inspector_id: string) {
    return firstValueFrom(
      this.http.get<any>(
        this.inspectorUrl + `/producedInspections/${inspector_id}`
      )
    ).catch((error) => {
      throw new Error(error);
    });
  }

  async getUserInspection(inspectionPdfId: string) {
    const token = await this.auth.token();
    return firstValueFrom(
      this.http.get<CustomFile>(
        this.clientUrl + `/userInspection/${inspectionPdfId}`
      )
    ).catch((error) => {
      throw new Error(error);
    });
  }

  async createNotConfirmedInspectionPurchase(purchase: Purchase) {
    return firstValueFrom(
      this.http.post<string>(this.clientUrl + '/createNotConfirmedPurchase', {
        data: purchase,
      })
    );
  }

  async createInspectionPurchase(purchase: Purchase) {
    return firstValueFrom(
      this.http.post<string>(this.clientUrl + '/createPurchase', {
        data: purchase,
      })
    );
  }

  async checkAdminDaysOff(): Promise<boolean> {
    return firstValueFrom(
      this.http.get<boolean>(this.inspectorUrl + '/checkDaysOff')
    );
  }

  async getMarketValue(
    make: string,
    model: string,
    milage: string,
    year: string,
    id: string
  ) {
    return firstValueFrom(
      this.http.get<string>(
        this.clientUrl +
          `/getMarketValue/${make}/${model}/${milage}/${year}/${id}`
      )
    ).catch((error) => {
      throw new Error(error);
    });
  }

  async verifyVin(niv: string): Promise<any> {
    return firstValueFrom(
      this.http.get<any>(this.inspectorUrl + `/vinDecoder/${niv}`)
    );
  }

  async createPsiInspection(psiForm: psi): Promise<any> {
    return firstValueFrom(
      this.http.post<string>(this.inspectorUrl + '/createPsiInspection', {
        data: psiForm,
      })
    );
  }

  async getPsiInspection(objectId: string): Promise<any> {
    return firstValueFrom(
      this.http.get<any>(this.inspectorUrl + `/getPsiInspection/${objectId}`)
    );
  }
}
