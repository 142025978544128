export const environment = {
  production: 'true',
  firebase: {
    apiKey: 'AIzaSyAktG_rbZmIF-vBTZg1v2kqAiPleOxky0I',
    authDomain: 'kiwiz-4bff3.firebaseapp.com',
    projectId: 'kiwiz-4bff3',
    storageBucket: 'kiwiz-4bff3.appspot.com',
    messagingSenderId: '602401148053',
    appId: '1:602401148053:web:cf2546236e17b5175ddf9c',
    measurementId: 'G-VSZ5CTFHNB',
  },
  apiUrl: 'https://api.carinspect.com/v1.0',
  url: 'https://app.carinspect.com',
  application_token: 'ff3e771c-eb08-11ea-9366-5076afa7169c',
  gtm: 'GTM-MVQC5TMS',
  stripe_key: 'pk_live_51ITpicBy4wlBwrL5rjmYM9qNcRk0bsk6x06oe3eo0C7jL8GffGHJiZhUIK2X6Afu8mmpekhjjNUuPbtOlWLKijZf002AYz15N5',
  google_maps_key: 'AIzaSyBBRduREYy8aYVshvLLRjKlqJ8Bez22LxU',
  reportPSIUrl: 'http://app.carinspect.com/client/psiIinspection/',
  reportUrl: 'http://app.carinspect.com/client/inspection/',
  sentryDsn: 'https://797051d70398888d4af4744e3999ea7f@o4508875146788864.ingest.us.sentry.io/4508976527310848',
};
