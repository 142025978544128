import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, APP_INITIALIZER, ErrorHandler} from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireAuthGuardModule } from '@angular/fire/compat/auth-guard';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Router } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FirebaseModule } from 'src/app/modules/firebase/firebase.module';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpErrorInterceptor } from './interceptors/http-error-interceptor';
import { CustomTranslateLoader } from './loaders/custom-translate-loader';
import { MaterialModule } from './material.module';
import { AuthenticationModule } from './modules/authentication/authentication.module';
import { HttpHeaderIntercepter } from './interceptors/http-header-intercepter';
import { InspectionSynthesisComponent } from './modules/client/components/client-inspections/inspection/complete-inspection/inspection-synthesis/inspection-synthesis.component';
import { InspectionReportComponent } from './modules/client/components/client-inspections/inspection/complete-inspection/inspection-report/inspection-report.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { GoogleMapsModule } from '@angular/google-maps';
import * as Sentry from '@sentry/angular';
import { browserTracingIntegration } from '@sentry/angular';

// ✅ Initialize Sentry before the module loads
if (environment.sentryDsn) {
  Sentry.init({
    dsn: environment.sentryDsn,
    integrations: [browserTracingIntegration()],
    tracesSampleRate: 1.0,
  });

  // ✅ Capture Global Errors
  window.onerror = function (message, source, lineno, colno, error) {
    console.error("❌ Global Error Captured:", message, error);
    Sentry.captureException(error || message);
  };

  // ✅ Capture Unhandled Promise Errors
  window.addEventListener("unhandledrejection", (event) => {
    console.error("❌ Unhandled Promise Rejection:", event.reason);
    Sentry.captureException(event.reason);
  });
}
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      defaultLanguage: "en",
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
      }
    }),
    MaterialModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    AngularFireMessagingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AuthenticationModule,
    FirebaseModule,
    MatStepperModule,
    MatSnackBarModule,
    GoogleMapsModule,
  ],
  providers: [
    InspectionSynthesisComponent,
    InspectionReportComponent,
    { 
      provide: 'googleTagManagerId',
      useValue: environment.gtm
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpHeaderIntercepter,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(), // ✅ Global Error Handling with Sentry
    },
    {
      provide: Sentry.TraceService, // ✅ Ensure tracing is enabled
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  exports: [
    TranslateModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
