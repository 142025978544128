import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { firstValueFrom, from, Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { User } from 'src/app/entities/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private jwtHelper = new JwtHelperService();

  private b2bLoginURL = environment.apiUrl + '/b2bClient/verifyBusinessPassword';

  constructor(public router: Router, private auth: AngularFireAuth, private http: HttpClient) { }

  public async isAuthenticated(): Promise<boolean> {
    const token = localStorage.getItem('idToken') ?? undefined;
    const isExpired = this.jwtHelper.isTokenExpired(token || '' );
    if (token == null) {
      return false;
    } else {
      if (isExpired == false) {
        return true;
      } else {
        const user = this.auth.currentUser;
        const newToken = await user?.then(user => user?.getIdToken());
        if (newToken) {
          localStorage.setItem('idToken', newToken);
          return true;
        }
      }
      return false;
    }
  }
  async verifyBusinessPassword(password: string) {
    const body = JSON.stringify({ password: password });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  
    return await firstValueFrom(
      this.http.post(`${this.b2bLoginURL}`, body, { headers: headers })
    )
    .catch(error => {
      throw error;
    });
  }

  public async token(): Promise<string | null> {
    var token = await firstValueFrom(this.getFBIdToken()) ?? null ;
    const isExpired = this.jwtHelper.isTokenExpired(token || '');

    if (isExpired) {
      token = await firstValueFrom(this.getFBIdToken()) ;
    }

    return token ;
  }

  getFBIdToken(): Observable<string | null> {
    return this.auth.authState.pipe(
      take(1),
      switchMap((user) => {
        if (user) {
          return from(user.getIdToken(true))
        }
        return of(null);
      })
    )
  }

  public async disconnect() {
    localStorage.removeItem('idToken');
    await this.auth.signOut();
    window.location.href = "https://carinspect.com/";
  }

  async getUser(): Promise<User | undefined> {
    const token = await this.token();

    if (token === null) {
      return undefined;
    }

    const url = `${environment.apiUrl}/users/me`;
    return firstValueFrom(this.http.get<User>(url)).catch(error => {
      throw error;
    });
  }

  public async login(idToken: string, origin: string | null) {
    localStorage.setItem('idToken', idToken);
    const user = await this.getUser();

    if (user?.emailVerified == false) {
      return this.router.navigate(['confirmation']);
    }

    if (origin != null) {
      return this.router.navigate([origin]);
    }
    else if (user?.role == 'b2b') {
      return this.router.navigate(['client/b2b/inspection']);
    }
    else if (user?.role == 'inspector') {
      return this.router.navigate(['inspector']);
    } else {
      return this.router.navigate(['client']);
    }
  }
}
